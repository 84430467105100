import { Link } from "gatsby"
import React from "react"

function PostCard({ post }) {
  return (
    <Link
      css={`
        color: currentColor;
        text-decoration: none;
        display: flex;
        flex-direction: column;

        &:hover {
          .read-article {
            color: #0095ff;
          }
        }
      `}
      to={`/blog/${post.slug}`}
    >
      <img
        css={`
          width: auto;
          height: 202px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 24px;
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.05);
          box-shadow: 3px 3px 9px 0px #e6e6e6, -3px -3px 9px 0px #fff,
            inset -3px -3px 6px 0px #fff, inset 3px 3px 6px 0px #d6d6d6;
          padding: 10px;
        `}
        src={post.widerMedia?.fluid.src}
        alt={post.title}
      />
      <div
        css={`
          margin-bottom: 28px;
        `}
      >
        <h2
          css={`
            font-size: 18px;
            font-weight: bold;
            line-height: 1.33;
            margin: 0;
            margin-bottom: 12px;
          `}
        >
          {post.title}
        </h2>
        {post.description && (
          <h3
            css={`
              font-size: 14px;
              opacity: 0.55;
              font-weight: 300;
              line-height: 1.14;
              margin: 0;
            `}
          >
            {post.description.description}
          </h3>
        )}
      </div>
      <div
        css={`
          display: flex;
          margin-top: auto;
        `}
      >
        <div
          css={`
            font-size: 14px;
            font-weight: 300;
            line-height: 1.14;
            opacity: 0.7;
            margin-right: 14px;
          `}
        >
          {post.date}
        </div>
        <div
          className="read-article"
          css={`
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            color: #1979ff;
            transition: color 250ms ease;
          `}
        >
          Read Article
        </div>
      </div>
    </Link>
  )
}

export default PostCard
