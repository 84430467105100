import React from "react"
import Layout from "../components/common/Layout"
import Container from "../components/common/Container"
import Divider from "../components/common/Divider"
import Crater from "../components/common/Crater"
import { SwiperSlide } from "swiper/react"
import Button from "../components/common/Button"
import { graphql, Link } from "gatsby"
import { uniq, flatten } from "lodash"
import { CATEGORIES, DEVICES } from "../config"
import SEO from "../components/common/SEO"
import CustomSwiper from "../components/common/CustomSwiper"
import { LIGHT_THEME } from "../theme"
import styled from "styled-components"
import PostCard from "../components/blog/PostCard"

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  margin-bottom: 44px;
  margin: 0;
  margin-bottom: 24px;
`

function CategoryButtons({ categories = [], checkOnActive = false, ...props }) {
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > * {
          margin: 7px;
        }
      `}
      {...props}
    >
      {categories.map(category => (
        <Button.Raised
          key={category.name}
          active={category.active}
          onClick={category.onClick}
        >
          {/* {checkOnActive ? (
            <ArrowCheck
              css={`
                display: ${category.active ? "block" : "none"};
                margin-right: 8px;
                && {
                  fill: #1979ff;
                  width: 24px;
                  height: 24px;
                }
              `}
            />
          ) : (
            category.icon
          )} */}
          <span>{category.name}</span>
        </Button.Raised>
      ))}
    </div>
  )
}

function ImageSlide({ image, title, slug, date }) {
  return (
    <Crater
      css={`
        padding: 10px;
      `}
    >
      <div
        css={`
          position: relative;
        `}
      >
        <img
          css={`
            width: 100%;
            height: 406px;
            object-fit: cover;
            border-radius: 10px;
            vertical-align: bottom;
          `}
          src={image}
          alt={title}
        />
        <div
          css={`
            border-radius: 10px;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            background: #000;
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.55),
              rgba(0, 0, 0, 0.55)
            );
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            padding-left: 56px;
            padding-bottom: 56px;

            @media (max-width: ${DEVICES.mobile}px) {
              padding-left: 28px;
              padding-bottom: 28px;
            }
          `}
        >
          <div>
            <div
              css={`
                margin-bottom: 12px;
                line-height: 1.29;
                font-size: 14px;
                color: var(--main);
              `}
            >
              {date}
            </div>
            <div
              css={`
                font-size: 24px;
                font-weight: bold;
                max-width: 500px;
                line-height: 1.33;
                color: #f3f3f3;
                margin-bottom: 32px;
              `}
            >
              {title}
            </div>
            <Button.Plain
              as={Link}
              to={slug}
              css={`
                text-decoration: none;
                color: var(--main);
              `}
            >
              Read Article
            </Button.Plain>
          </div>
        </div>
      </div>
    </Crater>
  )
}

function Blog({ data }) {
  const [levelOneCategory, setLevelOneCategory] = React.useState("All")
  const [levelTwoCategories, setLevelTwoCategories] = React.useState([])
  const posts = data.allContentfulBlogPost.edges.map(edge => edge.node)
  const featuredPosts = posts.filter(post => post.featured)
  const filteredPosts = posts
    .filter(post =>
      levelOneCategory === "All"
        ? true
        : post.categories.includes(levelOneCategory)
    )
    .filter(post =>
      levelTwoCategories.length
        ? post.categories.some(x => levelTwoCategories.includes(x))
        : true
    )

  function getSubCategories(categories = []) {
    return uniq(categories).map(category => ({
      name: category,
      hasPosts: posts
        .filter(post =>
          levelOneCategory === "All"
            ? true
            : post.categories.includes(levelOneCategory)
        )
        .find(post => post.categories.includes(category)),
      active: levelTwoCategories.includes(category),
      onClick: () =>
        levelTwoCategories.includes(category)
          ? setLevelTwoCategories(
              levelTwoCategories.filter(x => x !== category)
            )
          : setLevelTwoCategories([...levelTwoCategories, category]),
    }))
  }

  return (
    <Layout theme={LIGHT_THEME}>
      <SEO title="Blog" />
      <Divider
        css={`
          margin-bottom: 80px;
        `}
      />
      <Container compact>
        <Title>Latest Articles</Title>
      </Container>

      <main
        css={`
          padding-bottom: 10rem;
        `}
      >
        <Container
          compact
          css={`
            margin-bottom: 120px;
          `}
        >
          <CustomSwiper
            id="blog"
            css={`
              .swiper-next {
                right: -64px;
              }

              .swiper-prev {
                left: -64px;
              }

              .swiper-pagination {
                bottom: -64px;

                .swiper-pagination-bullet-active {
                  background: transparent;
                  border: 2px solid #0095ff;
                  box-shadow: 5px 5px 10px 0px #c2c6cc, -5px -5px 10px 0px #fff;
                }
              }

              @media (max-width: ${DEVICES.tablet}px) {
                .swiper-next {
                  right: -32px;
                }

                .swiper-prev {
                  left: -32px;
                }
              }
            `}
            slidesPerView={1}
            arrowSize={32}
            pagination={{ clickable: true, el: ".blog-pagination" }}
            navigation={{
              nextEl: ".blog-next",
              prevEl: ".blog-prev",
            }}
          >
            {featuredPosts.map(post => (
              <SwiperSlide key={post.contentful_id}>
                <ImageSlide
                  date={post.date}
                  title={post.title}
                  slug={post.slug}
                  image={post.widerMedia?.fluid.src}
                />
              </SwiperSlide>
            ))}
          </CustomSwiper>
        </Container>
        <Container compact>
          <Divider
            css={`
              margin: 64px 0;
            `}
          />

          <Title>Blog Articles</Title>
          {/* <div
            css={`
              margin-bottom: 72px;
            `}
          >
            <div
              css={`
                margin-bottom: 24px;
              `}
            >
              <CategoryButtons
                categories={CATEGORIES.map(category => ({
                  ...category,
                  active: levelOneCategory === category.name,
                  onClick: () => {
                    setLevelOneCategory(category.name)
                    setLevelTwoCategories([])
                  },
                })).filter(x =>
                  x.name === "All"
                    ? true
                    : posts.find(post => post.categories.includes(x.name))
                )}
              />
            </div>
            <div
              css={`
                padding: 20px 24px 20px 23px;
                border-radius: 20px;
                box-shadow: inset -3px -3px 5px 0 var(--color-shadow-one),
                  inset 3px 3px 5px 0 var(--color-shadow-two);
              `}
            >
              <CategoryButtons
                checkOnActive
                categories={getSubCategories(
                  levelOneCategory === "All"
                    ? flatten(
                        CATEGORIES.map(category => category.categories)
                      ).filter(x => x)
                    : CATEGORIES.find(x => x.name === levelOneCategory)
                        .categories
                ).filter(x => x.hasPosts)}
              />
            </div>
          </div> */}
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(250px, 325px));
              gap: 56px 24px;
            `}
          >
            {filteredPosts.map(post => (
              <PostCard key={post.contentful_id} post={post} />
            ))}
          </div>
        </Container>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AllPosts {
    allContentfulBlogPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          slug
          description {
            description
          }
          categories
          featured
          widerMedia {
            contentful_id
            fluid {
              src
            }
          }
          date(formatString: "MMMM DD, yyyy")
        }
      }
    }
  }
`

export default Blog
