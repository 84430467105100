import styled from "styled-components"

const Crater = styled.div`
  box-shadow: inset -3px -3px 5px 0 var(--color-shadow-one),
    inset 3px 3px 5px 0 var(--color-shadow-two);
  background-color: var(--main);
  border-radius: 10px;
`

export default Crater
